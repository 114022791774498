import { useRef, useState } from 'react'
import { Points, PointMaterial } from '@react-three/drei'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { inSphere } from 'maath/random'
import { TextureLoader, Mesh } from 'three'
import { OrbitControls } from '@react-three/drei'

function Stars(props:any) {
    const ref = useRef()
    const [sphere] = useState(() => inSphere(new Float32Array(5001), { radius: 1.6 }))
    useFrame((state, delta) => {
        // @ts-ignore
        ref.current.rotation.x -= delta / 1140
        // @ts-ignore
        ref.current.rotation.y -= delta / 1150
      })
    return (
      <group position={[0,0,0]}>
        <Points ref={ref}  positions={sphere} stride={3} frustumCulled={false} {...props}>
          <PointMaterial transparent color="#ffa0e0" size={0.002} sizeAttenuation={true} depthWrite={false} />
        </Points>
      </group>
    )
  }

export const Space = ({hover}:{hover?:boolean}) => {
    const texture = useLoader(TextureLoader, 'space/neptune/textures/Material.003_diffuse.jpeg')
    const planet = useRef<Mesh>(null!)
    const [hovering, setHovering] = useState(false)
    const [width] = useState<number>(window.innerWidth);

    useFrame(({  }) => {
        if (hovering || hover)
        planet.current.rotation.y = planet.current.rotation.y += 0.01
        else {
            planet.current.rotation.y = planet.current.rotation.y += 0.001
        }
      })

    
  return (
    <>
        {/*<ambientLight  />*/}
        <pointLight position={[1, 1, -1]} intensity={2.5} />
        <group position={[0, 0.04, 0]} onClick={()=>{ width < 1000 && setHovering(!hovering)}}>
        <mesh rotation={[10, 0, 0]} scale={0.5} ref={planet}>
            {/*<boxGeometry attach="geometry" args={[1, 1, 1]} />*/}
            <sphereGeometry attach="geometry" args={[1, 100, 100 ]} />
            <meshStandardMaterial attach="material" map={texture} />
        </mesh>
        </group>
        <Stars/>
    </>
  )
}
