import Hero from "./Hero"
import Portfolio from "./Portfolio"
import Form from "./Form"
import About from "./About"
import NewHero from "./NewHero"
import Wip from "./Wip"
import Corgi from "../../components/ui/Corgi"
import { Space } from "./Space"
import { Canvas } from "@react-three/fiber"
import { Suspense, useState } from "react"
import { Loader } from "@react-three/drei"

/*<NewHero />
            <About/>
            <Portfolio />
            <Form />*/
const Home = () => {
    const [hover, setHover] = useState(false)

    return (
        <div className="content" style={{backgroundColor: 'black', height:'100vh'}}>
            <div style={{position:'absolute', textAlign:'center' , width:'70vw', display:'flex', gap:'2vh', flexDirection:'column', bottom:'6vh', zIndex:'100', left:'50vw', transform: 'translateX(-50%)', color:'white'}}>
            <h2 >Hi! I'm Renzo.</h2>
            <h4 >I'm updating this, to keep in touch send me an email:</h4>
            <a href={'mailto:hello@renzoparagliola.com'} >
                <h4 style={{color:'white', textDecoration:'underline'}} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>hello@renzoparagliola.com</h4>
            </a>
            </div>


            <Canvas camera={{ position: [0, 0, -1] }}>
                <Space hover={hover} /> 
            </Canvas>
        </div>
    )
}

export default Home;