import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        resources: {
            en:{
                translation:{
                    OppositeLanguage : 'italian',
                    MenuAbout : 'about',
                    MenuProjects : 'projects',
                    MenuContacts : 'contacts',
                    WelcomeText : "I'm a developer specialised in crafting innovative web experiences in the art field. Currently I'm working on some personal projects but I'm open to new opportunities.",
                    ResumeButton : "Get my resume",
                    FindMoreButton : "See my works",
                    ResumeURI : "https://res.cloudinary.com/daduurukb/image/upload/v1683848834/Crescenzo_Paragliola_-_CV.pdf",
                    PlanetaryButton : "Visit the planetary!",
                    QuitPlanetary : "Quit the planetary!",
                    PlanetaryDescr : "The planetary shows the stylised, but accurate, real time positions of the planets of the solar system. Entirely based on math calculation, this is a part of a more complex project coming up in the next months.",
                    Venus : "Venus",
                    Mercury : "Mercury",
                    Earth : "Earth",
                    Mars : "Mars",
                    Jupiter : "Jupiter",
                    AboutHeader : 'About Me',
                    AboutText : "Hi! I’m Renzo and I create things.<br/>What kind of things? Mainly web-related things, like web apps. But I have a wide range of interests and over the years I've had the opportunity to work on many projects I loved. My passion for contemporary art led me to start an art gallery and work with many international artists. My passion for algebra and algorithms led me to a computer science degree and a career in software development.",
                    AboutTech : "Some tecnologies I've been working with recently:",
                    ProjectsHeader: "Projects",
                    ProjectsSubHeader : "A selection of works from my portfolio.",
                    ProjectPageSubHeader: "Here you can find a list of my works. You can filter the elements by technology used.",
                    ProjectMore :"See All",
                    FormHeader : "Let's get in touch",
                    FormIntro : "Hello Renzo!",
                    FormNameLabel: "My name is:",
                    FormName: "Name",
                    FormCompanyName:"from",
                    FormCompany:"Company name (if applicable)",
                    FormTopicLabel: "I'd like to discuss:",
                    FormTopicOpt1:"App",
                    FormTopicOpt2:"Website",
                    FormTopicOpt3:"Job Offer",
                    FormTopicOpt4:"Something Else",
                    FormTopicDescr1:"Brief description of the project",
                    FormTopicDescr2:"Something else to add?",
                    FormContactBack:"Contact me back at:",
                    FormSubmit:"Submit",
                    ContactDescription:"I'm busy again, I'll be available from July. It's a good idea to send me project or job offers early.",
                    FormConfirm:"<4>Hello,</4> <4>I received your request and I'll get in touch very soon. </4> <4> Best regards.</4> <4>RP</4>",
                    FormRequired:"Required.",
                    FormMailNotVaild:"Invalid email address.",
                    WIP:"Update in progress...",
                }
            },
            it: {
                translation:{
                    OppositeLanguage : 'english',
                    MenuAbout : 'about',
                    MenuProjects : 'progetti',
                    MenuContacts : 'contatti',
                    WelcomeText : "Sono uno sviluppatore specializzato nella creazione di progetti nel campo dell'arte. Attualmente sto ultimando alcuni progetti e sono disponibile a valutare nuove opportunità.",
                    ResumeButton : "Scarica il mio CV",
                    FindMoreButton : "Vedi i miei lavori",
                    ResumeURI : "https://res.cloudinary.com/daduurukb/image/upload/v1683848834/Crescenzo_Paragliola_-_CV.pdf",
                    PlanetaryButton : "Visita il planetario!",
                    QuitPlanetary : "Esci dal planetario!",
                    PlanetaryDescr : "Il planetario mostra la posizione stilizzata, ma precisa, dei pianeti del sistema solare. Basato su calcoli matematici, è il preludio di un progetto più complesso che sarà rivelato nei mesi a venire.",
                    Venus : "Venere",
                    Mercury : "Mercurio",
                    Earth : "Terra",
                    Mars : "Marte",
                    Jupiter : "Giove",
                    AboutHeader : 'Su di me.',
                    AboutText : "Ciao, sono Renzo, e mi occupo di web. In particolare app. La passione per l'arte mi ha portato dapprima a lavorare per vari artisti, poi a fondare una galleria d'arte. A seguito di queste esperienze, ho deciso di continuare ad approfondire il percorso sul web, in quanto forma di comunicazione estemamente potente e trasversale. Ho deciso quindi di intraprendere un percorso accademico e indirizzarmi con decisione verso una carriera nello sviluppo software.",
                    AboutTech : "Tecnologie con cui ho lavorato di recente:",
                    ProjectsHeader: "Progetti",
                    ProjectsSubHeader : "Una selezione di lavori dal mio portfolio.",
                    ProjectPageSubHeader: "Qui puoi trovare una lista di lavori selezionati. Puoi filtrare la lista in base alla tecnologia usata.",
                    ProjectMore :"Vedi altri.",
                    FormHeader : "Contattami",
                    FormIntro : "Ciao Renzo!",
                    FormNameLabel: "Io sono:",
                    FormName: "Nome",
                    FormCompanyName:"scrivo per conto di:",
                    FormCompany:"Nome della compagnia (se è questo il caso)",
                    FormTopicLabel: "Vorrei discutere di::",
                    FormTopicOpt1:"App",
                    FormTopicOpt2:"Sito Web",
                    FormTopicOpt3:"Offerta di lavoro",
                    FormTopicOpt4:"Qualcos'altro.",
                    FormTopicDescr1:"Descrizione semplice del progetto",
                    FormTopicDescr2:"Qualcos'altro da aggiungere?",
                    FormContactBack:"Ricontattami alla mail:",
                    FormSubmit:"Invia",
                    ContactDescription:"Sono nuovamente occupato, sarò disponibile da Luglio, è utile comunque inviare richieste di progetto o lavorative con un certo anticipo.",
                    FormConfirm:"<4> Tutto chiaro,</4> <4>Ho ricevuto il tuo messaggio e ti ricontatterò a breve.</4> <4> A presto.</4> <4>RP</4>",
                    FormRequired:"Campo richiesto.",
                    FormMailNotVaild:"Mail non valida.",
                    WIP:"Aggiornamento in corso...",
                
                }
            }
        }
    })